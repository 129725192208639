@font-face {
  font-family: hwzs;
  src: url("./stzhongs.ttf") format("truetype");
}

body {
  font-family: hwzs;
  margin: 0;
  padding: 0;
  max-width: 100%;
}
@media print {
  .no-print {
    display: none !important;
  }
  table {
    page-break-inside: auto;
  }
  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }
  thead {
    display: table-header-group;
  }
  tfoot {
    display: table-footer-group;
  }
  .no-break-inside {
    page-break-inside: avoid;
  }
}
